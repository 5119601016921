import {
  GetStaticProps,
  GetStaticPaths,
} from "next";
import IndexPageIndiaV2 from "../../../Layouts/India/IndexPageIndiaV2";
import { IHomeApiData, IHomePageLanguageData } from "../../../lib/types/home";
import {
  HOME_PAGE,
  REVALIDATE,
  pagesWithLocale,
  HOME_PAGE_URL,
} from "../../../lib/utils/constants/GlobalConstants";
import {
  getCountryFromLocale,
  getLangugageToggleData,
  getStaticPagePath,
} from "../../../lib/utils/constants/PagesConstants";
import { getAdditionalDataHome } from "../../../lib/utils/helpers/additionalData";
import { getGlobalData, getNonApiData } from "../../app/utils/get-data";
import { IHomeUSApiData } from "../../../lib/types/home.us";
import { ABTestingData, BaseProps } from "../../../lib/types/Misc.type";
import SiteNavigationSchema from "../../../lib/schemas/siteNavigationSchema";
import { COUNTRY } from "../../../lib/utils/constants/LocaleAndCountryConstants";

type Props = {
  apiData: IHomeApiData | IHomeUSApiData | any;
  autoScrollToElement: () => void;
  url: string;
  installStepContainer: React.RefObject<HTMLElement>;
  downloadUrl: string;
  androidUrl: string;
  abTestingData: ABTestingData;
} & BaseProps;

const Home = (props: Props) => {
  return (
    <>
      {getCountryFromLocale(props.apiData.locale) === COUNTRY.GERMANY && <SiteNavigationSchema />}
      <IndexPageIndiaV2 {...props} />
    </>
  );
};

export const getStaticPaths: GetStaticPaths = async () => {
  let homePagePaths = getStaticPagePath(
    pagesWithLocale.get(HOME_PAGE_URL)?.locales!,
    "locale"
  );
  return {
    paths: homePagePaths,
    fallback: false,
  };
};

export const getStaticProps: GetStaticProps = async (props) => {
  const locale = props.params!.locale! as string;
  const pageName: string = HOME_PAGE_URL;
  const jsonName: string = HOME_PAGE;

  const languageToggleData = getLangugageToggleData(
    pagesWithLocale,
    pageName,
    locale
  );

  const {
    appVersions,
    abTestingData,
    brandModelData,
    deviceArchitectureMapping,
  } = await getNonApiData(locale);

  const data = await getGlobalData<IHomePageLanguageData>(
    locale,
    jsonName,
    pageName,
    languageToggleData,
    HOME_PAGE_URL,
    getAdditionalDataHome
  );

  return {
    props: {
      ...data,
      appVersions,
      brandModelData,
      abTestingData,
      deviceArchitectureMapping,
    },
    revalidate: REVALIDATE,
  };
};

export default Home;
